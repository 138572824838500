// Dependency
import React, { useState } from "react";
import { graphql } from "gatsby";

// Internationalization
//import i18n from "../../internationalization/i18n";
import { useTranslation } from 'react-i18next';

// Components
import Layout from "../../components/layouts";
import Tiles from "../../components/tiles/tiles";
import ProductCard from "../../components/cards/product-card";
import ProductSwiper from "../../components/product-swiper";
import ProductList from "../product/hooks/product-list";
// import ProductCardGrid from "../../components/cards/product-card-grid/product-card-grid";

// Styles
import * as shopStyles from './shop.module.scss'

// Data
export const query = graphql`
  query ($locale: String! = "en", $category2: String!, $category3: String!, $category4: String!, $category5: String!, $category6: String!, $category7: String!, $category8: String!, $category9: String!, $category10: String!){
    centraData: centra {
      products {
        name
        id
        prices {
          price {
            value
            formattedValue
          }
        }
        media {
          source {
            type
            url
          }
        }
        collection {
          name
          id
          uri
        }
      }
    }
    shopData: datoCmsShopPage (locale: {eq: $locale}){
      locale
      title
      tiles{
        isAnchor
        title
        link
      }
      bestseller{
        centraId
        slug
        featuredImage{
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        newPrice
        locale
        title
        parentProductName
        trustpilotId
        saleBadge
        bestseller
      }
      seoMetaTags {
        tags
        id
      }
    }
    recovery: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category2 }}}}
    ) {
      nodes {
        centraId
        id
        title
        productType
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
    reuse: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category8 }}}}
    ) {
      nodes {
        centraId
        id
        title
        productType
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
    events: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category9 }}}}
    ) {
      nodes {
        centraId
        id
        title
        productType
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
    redLight: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category10 }}}}
    ) {
      nodes {
        centraId
        id
        title
        productType
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
    training: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category3 }}}}
    ) {
      nodes {
        centraId
        id
        title
        productType
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
    yoga: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category5 }}}}
    ) {
      nodes {
        centraId
        id
        title
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
    bundles: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category6 }}}}
    ) {
      nodes {
        centraId
        id
        title
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
     deals: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category7 }}}}
    ) {
      nodes {
        centraId
        id
        title
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
      accessories: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $category4 }}}}
    ) {
      nodes {
        centraId
        id
        title
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        productCategory {
          name
          slug
        }
      }
    }
  }
`;

export default function Shop(props) {

  // Recovery category products
  const recovery = props.data.recovery.nodes;
  // Events category products
  const events = props.data.events.nodes;
  // Events category products
  const redLight = props.data.redLight.nodes;
  // Reuse category products
  const reuse = props.data.reuse.nodes;
  // Trai category products
  const training = props.data.training.nodes;
  // Yoga category products
  const yoga = props.data.yoga.nodes;
  // Bundle category products
  const bundles = props.data.bundles.nodes;
  // Acc category products
  const accessories = props.data.accessories.nodes;
  // Shop Data
  const shopData = props.data.shopData;
  // Translation Function
  const { t } = useTranslation();
  //
  const [productList, setProductList] = useState([]);

  const navIsOpen = props.location && props.location.state ? props.location.state.navIsOpen : false;

  return (
    <Layout dropDownOpened={navIsOpen} seoData={props.data.shopData.seoMetaTags} country={props.pageContext.country} location={props.location}>
      <ProductList createProductList={setProductList} isCampaign={props.location.pathname === `/${props.pageContext.country}/shop/member/`} country={props.pageContext.country} />
      <Tiles data={shopData.tiles} country={props.pageContext.country} />

      <div className={shopStyles.cdShopWrapper}>
        <section className={shopStyles.cdShopBestsellerSection} id="bestsellers">
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title1')}</h2>
            </div>
          </div>
          <div className={`cd-max-width ${shopStyles.cdShopGridWrapper}`}>
            <ProductSwiper productList={productList} data={shopData.bestseller} placement="BestSeller List"></ProductSwiper>
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category10}>
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title10')}</h2>
              <span></span>
            </div>
          </div>
          {/* <div className={`cd-max-width`}>
                    <ProductSwiper data={training} />
                  </div>*/}
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              redLight && redLight.map((element, index) => {
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} data={element} position={index + 1} placement="Training List" />
                  </div>
                )
              }
              )}
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category2}>
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title2')}</h2>
              <span></span>
            </div>
          </div>
          <div className={`cd-max-width`}>
            {/* products && <ProductCardGrid columns={"three"} products={products} limit={5}>
                        <div className={` ${shopStyles.cdShopProductCard} ${shopStyles.cdShopBackgroundCard}  cd-shop-product-card`}>
                          <BackgroundCard
                            data={{
                              subTitle: 'Nu ännu starkare',
                              title: 'Jämför Massageguns',
                              featuredMedia: {
                                  url: 'https://flowlifesweden.com/wp-content/uploads/2020/08/Flowgun_Ta-din-återhämtning-till-en-ny-nivå_Mobil-1.jpg'
                              },
                              linkName: 'Se Massagepistoltestet',
                              linkURL: '/compare',
                            }}
                          />
                        </div>
                        </ProductCardGrid>
                    */}
          </div>
          {/*<div className={`cd-max-width`}>
                      <ProductSwiper data={recovery}>
                        <div className={` ${shopStyles.cdShopProductCard} ${shopStyles.cdShopBackgroundCard}  cd-shop-product-card`}>
                          <BackgroundCard
                            data={{
                              subTitle: 'Nu ännu starkare',
                              title: 'Jämför Massageguns',
                              featuredMedia: {
                                  url: 'https://flowlifesweden.com/wp-content/uploads/2020/08/Flowgun_Ta-din-återhämtning-till-en-ny-nivå_Mobil-1.jpg'
                              },
                              linkName: 'Se Massagepistoltestet',
                              linkURL: '/compare',
                            }}
                          />
                        </div>
                      </ProductSwiper>
                    </div>*/}
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              recovery && recovery.map((element, index) => {
                //Get mapped Centra Prod
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} position={index + 1} data={element} placement="Recovery List" />
                  </div>
                )
              }
              )}
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category8}>
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title8')}</h2>
              <span></span>
            </div>
          </div>
          {/* <div className={`cd-max-width`}>
                    <ProductSwiper data={training} />
                  </div>*/}
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              reuse && reuse.map((element, index) => {
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} data={element} position={index + 1} placement="Training List" />
                  </div>
                )
              }
              )}
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category3}>
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title3')}</h2>
              <span></span>
            </div>
          </div>
          {/* <div className={`cd-max-width`}>
                    <ProductSwiper data={training} />
                  </div>*/}
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              training && training.map((element, index) => {
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} data={element} position={index + 1} placement="Training List" />
                  </div>
                )
              }
              )}
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category5}>
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title5')}</h2>
              <span></span>
            </div>
          </div>
          {/* <div className={`cd-max-width`}>
                    <ProductSwiper data={training} />
                  </div>*/}
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              yoga && yoga.map((element, index) => {
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} data={element} position={index + 1} placement="Yoga List" />
                  </div>
                )
              }
              )}
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category6} >
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title6')}</h2>
              <span></span>
            </div>
          </div>
          <div className={`${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              bundles && bundles.map((element, index) => {
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })
                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} data={element} />
                  </div>
                )
              }
              )}
          </div>
        </section>

        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category4} >
          <div className="cd-max-width">
            <div className={shopStyles.cdShopHeading}>
              <h2>{t('shop.title4')}</h2>
              <span></span>
            </div>
          </div>
          <div className={`${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              accessories && accessories.map((element, index) => {
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })
                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} data={element} />
                  </div>
                )
              }
              )}
          </div>
        </section>
      </div>
    </Layout>
  )
}